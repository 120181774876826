@use "../../config/colors";

.discover_tab {
  width: 35vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 4vw 0vw;
  margin-right: 4vw;
  margin-left: 2vw;
  opacity: 0.4;

  .holder {
    width: 100%;
    padding-top: 2.5vw;
    margin: 0.5vw 0vw;
    background-color: colors.$grey;
  }
  .tab_content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 0.2vw solid colors.$grey;
    padding: 1.6vw 1vw;
    border-radius: 1vw;

    .float {
      min-width: 40%;
      width: fit-content;
      padding: 0.2vw 2vw;
      background-color: colors.$shade;
      border: 0.2vw solid colors.$grey;
      border-radius: 5vw;
      align-self: flex-end;
      margin-top: -3.5vw;
      margin-bottom: 1vw;
      .holder {
        width: 100%;
        margin: 0.2 0vw;
      }
    }

    .a {
      width: 80%;
      .holder {
        padding-top: 7vw;
        margin: 1.3vw 0vw;
      }
    }
    .b {
      width: 80%;
      .holder {
        padding-top: 10vw;
        margin: 2.5vw 0vw;
      }
    }
    .c {
      width: 60%;
      .holder {
        padding-top: 7vw;
        margin: 1.3vw 0vw;
      }
    }
    .d {
      width: 80%;
      .holder {
        padding-top: 10.5vw;
        margin: 1.3vw 0vw;
      }
    }
  }
}
