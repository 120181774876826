@use "./config/colors";

body,
html {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  scroll-behavior: smooth;
  font-family: Arial, sans-serif;
  user-select: none;
}

.App {
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  .navbar {
    overflow: hidden;
    position: fixed;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    top: 0;
    padding: 1vw 0vw;
    z-index: 10000;
    transition: background-color 0.5s;
    box-shadow: colors.$box_shadow;
    .menu-container {
      display: none;
      .hamburger_ct {
        display: none;
      }
    }

    .nav_content {
      width: 90vw;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }
    img {
      margin: 0;
      padding: 0;
      width: 16vw;
    }
    .nav_btn {
      width: fit-content;
      height: fit-content;
      padding: 1vh 1.2vw;
      background-color: #f26739;
      border-radius: 50%;
      box-shadow: 0 1px 1px hsl(0deg 0% 0% / 0.075),
        0 2px 2px hsl(0deg 0% 0% / 0.075), 0 4px 4px hsl(0deg 0% 0% / 0.075),
        0 8px 8px hsl(0deg 0% 0% / 0.075), 0 16px 16px hsl(0deg 0% 0% / 0.075);

      p {
        font-size: 1.6vw;
        margin: 0;
        padding: 0;
        color: #ffffff;
      }
      &:hover {
        scale: 1.06;
        cursor: pointer;
      }
    }
  }

  .navbar.transparent {
    background-color: transparent;
  }

  .navbar:not(.transparent) {
    background-color: #5e3806;
  }

  .navbar ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
  }

  .navbar li {
    width: fit-content;
    height: fit-content;
    padding: 0.8vh 0.8vw;
    font-size: 1.6vw;
    cursor: pointer;
    color: #fff;
    @media (max-width: 980px) {
      color: #000;
    }
  }

  .navbar li.active {
    background-color: #fff;
    color: #f26739;
  }

  .navbar li:hover {
    background-color: #ddd;
    color: #f26739;
  }

  .navbar a {
    color: white;
    text-decoration: none;
    display: block;
  }

  .section {
    width: 100vw;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

@media (max-width: 980px) {
  // tablet
  .App {
    .navbar {
      margin: 0;
      padding: 0vw;

      .nav_content {
        width: 100%;
        padding: 0vw;
        background-color: colors.$white;

        ul,
        .nav_btn {
          display: none;
        }

        img {
          width: 70vw;
        }

        .menu_container {
          width: fit-content;
          height: fit-content;
          display: block;
          position: relative;
          padding-right: 3vw;
        }
      }

      .menu_content {
        width: 100vw;
        align-self: flex-end;
        background-color: colors.$white;

        ul {
          width: 100%;
          display: flex;
          flex-direction: column;
          list-style: none;
          padding: 0;
          margin: 0;
          user-select: none;
          li {
            width: 100%;
            text-align: center;
            margin: 10px 0;
            font-size: 5vw;
          }
          li:active {
            background-color: transparent;
          }
        }
      }
    }

    .navbar:not(.transparent) {
      background-color: colors.$white;
      padding: 2vw 0vw;
    }

    .section {
      min-height: fit-content;
      height: fit-content;
    }
  }
}

@media (max-width: 768px) {
  // Mobile view
  .App {
    .navbar {
      margin: 0;
      padding: 0vw;

      .nav_content {
        width: 100%;
        padding: 0vw;
        background-color: colors.$white;

        ul,
        .nav_btn {
          display: none;
        }

        img {
          width: 70vw;
        }

        .menu_container {
          width: fit-content;
          height: fit-content;
          display: block;
          position: relative;
          padding-right: 3vw;
        }
      }

      .menu_content {
        width: 100vw;
        align-self: flex-end;
        background-color: colors.$white;

        ul {
          width: 100%;
          display: flex;
          flex-direction: column;
          list-style: none;
          padding: 0;
          margin: 0;
          user-select: none;
          li {
            width: 100%;
            text-align: center;
            margin: 10px 0;
            font-size: 5vw;
          }
          li:active {
            background-color: transparent;
          }
        }
      }
    }

    .navbar:not(.transparent) {
      background-color: colors.$white;
      padding: 2vw 0vw;
    }

    .section {
      min-height: fit-content;
      height: fit-content;
    }
  }
}
