@use "../../config/colors";
.contact_container {
  width: 100vw;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10vw;
  padding: 10vw 0vw;
  background-color: #fde1d9;

  .contact_content {
    width: 90vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    h2 {
      color: #f26739;
      font-size: 6vw;
      span {
        color: #f26739;
        font-size: 3vw;
      }
    }

    h3 {
      font-size: 5vw;
      margin: 2vw 0vw;
      text-align: center;
    }

    .address_cnt {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 4vw;

      p {
        font-size: 5vw;
        color: colors.$black;
        margin-bottom: 2vw;
        text-align: center;
        margin-top: 4vw;
      }
      span {
        font-size: 5vw;
        color: colors.$grey;
        text-align: center;
      }
    }

    .social_media {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 20vw;

      .media_links {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin: 4vw 0vw;
      }

      img {
        width: 80vw;
        height: fit-content;
      }

      a {
        text-decoration: none;
        margin: 0vw 2vw;
        &:hover {
          opacity: 0.8;
        }
      }
    }
  }
}
