@use "../config/colors";

.container {
  position: relative;
  width: 90vw;
  height: 100%;
  display: grid;
  grid-template-columns: auto 48%;

  .content_ct {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .circle {
      width: 30vw;
      height: 30vw;
      border-radius: 50%;
      background-color: #ebad676e;
      position: absolute;
      z-index: 1001;
      top: -10vh;
      left: -10vw;
    }

    .inner_ct {
      margin-top: -10vh;
      z-index: 1002;
      h1 {
        width: 90%;
        font-size: 3.7vw;
        text-align: left;
        color: #fff;
      }

      .btns_ct {
        display: flex;
        flex-direction: row;
        margin-top: 8vw;
        .btn {
          width: fit-content;
          height: fit-content;
          background-color: #fff;
          margin-right: 3vw;
          padding: 0.8vw 1.4vw;
          border-radius: 50%;
          box-shadow: 0 1px 1px hsl(0deg 0% 0% / 0.075),
            0 2px 2px hsl(0deg 0% 0% / 0.075), 0 4px 4px hsl(0deg 0% 0% / 0.075),
            0 8px 8px hsl(0deg 0% 0% / 0.075),
            0 16px 16px hsla(0, 34%, 73%, 0.075);

          cursor: pointer;
          &:hover {
            scale: 1.06;
          }

          p {
            font-size: 1.6vw;
            color: colors.$primary;
          }
        }
        .dark {
          background-color: colors.$primary;
          p {
            color: #fff;
          }
        }
      }
    }
  }

  .img_sct {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    padding-top: 8vw;

    img {
      width: 34vw;
      height: fit-content;
    }

    .img_float {
      width: fit-content;
      height: fit-content;
      margin-top: 10vh;
      margin-left: -9vw;

      img {
        width: 12vw;
      }
    }
  }
}
@media (max-width: 980px) {
  // Tablet view

  .container {
    width: 100vw;
    padding-top: 20vw;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;

    .content_ct {
      align-items: center;

      .inner_ct {
        margin-top: 0vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        h1 {
          font-size: 9vw;
          text-align: center;
          text-shadow: colors.$box_shadow;
        }

        .btns_ct {
          justify-content: space-between;
          margin-top: 8vw;
          .btn {
            padding: 2vw;
            box-shadow: colors.$box_shadow;

            p {
              font-size: 6vw;
              color: colors.$primary;
            }
          }
          .dark {
            margin-right: 8vw;
            background-color: colors.$primary;
            p {
              color: #fff;
            }
          }
        }
      }
    }

    .img_sct {
      width: fit-content;

      img {
        width: 65vw;
        border-radius: 50%;
        box-shadow: colors.$box_shadow;
      }

      .img_float {
        width: fit-content;
        margin-top: 15vw;
        margin-left: -15vw;

        img {
          width: 20vw;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  // Mobile view

  .container {
    width: 100vw;
    padding-top: 20vw;
    display: flex;
    flex-direction: column-reverse;

    .content_ct {
      align-items: center;

      .inner_ct {
        margin-top: -10vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        h1 {
          font-size: 12vw;
          text-align: center;
          text-shadow: colors.$box_shadow;
        }

        .btns_ct {
          justify-content: space-between;
          .btn {
            padding: 3vw 3vw;
            box-shadow: colors.$box_shadow;

            p {
              font-size: 8vw;
              color: colors.$primary;
            }
          }
          .dark {
            margin-right: 6vw;
            background-color: colors.$primary;
            p {
              color: #fff;
            }
          }
        }
      }
    }

    .img_sct {
      width: fit-content;

      img {
        width: 75vw;
        border-radius: 50%;
        box-shadow: colors.$box_shadow;
      }

      .img_float {
        margin-top: 15vw;
        margin-left: -15vw;

        img {
          width: 30vw;
        }
      }
    }
  }
}
