.tabs_container {
  width: 90vw;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 4vw;

  .tabs_header {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: row;
    .tab {
      width: 12vw;
      background-color: #fff;
      display: flex;
      flex-direction: column;
      align-items: center;
      border-radius: 0.5vw;
      margin: 0vw 1.5vw;
      cursor: pointer;
      position: relative;
      box-shadow: 0 1px 1px hsl(0deg 0% 0% / 0.075), 0 2px 2px #f2673917,
        0 4px 4px #f2673917, 0 8px 8px #f2673917, 0 16px 16px #f2673917;
      &:hover {
        background-color: #fde1d9;
      }
      .icon_circle {
        width: 5vw;
        height: 5vw;
        margin-top: -1.8vw;
        background-color: #fde1d9;
        border: 0.1vw solid #f26739;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          height: 2.2vw;
        }
      }
      p {
        margin-top: 1vw;
        font-size: 1.4vw;
        text-align: center;
        margin-bottom: 0.5vw;
      }

      .arrow {
        width: 0;
        height: 0;
        transform: rotate(180deg);
        border-left: 0.7vw solid transparent;
        border-right: 0.7vw solid transparent;
        border-bottom: 1.4vw solid transparent;
        margin-bottom: -1.1vw;
      }
      .arrow_on {
        display: block;
        border-bottom: 1vw solid #f26739;
      }
    }
    .active {
      background-color: #f26739;
      &:hover {
        background-color: #f26739;
      }
      p {
        color: #fff;
      }
    }
  }

  .tabs_content {
    width: 100%;
    margin-top: 5vw;

    .content {
      width: 100%;
      display: grid;
      grid-template-columns: 60% auto;

      .details_cnt {
        width: 100%;
        h4 {
          font-size: 2vw;
        }

        h5 {
          font-size: 1.3vw;
          margin-top: 1.5vw;
          color: #5a270c;
        }

        p {
          font-size: 1.3vw;
          margin-top: 1vw;
          color: #4d4d4d;
        }
      }

      img {
        width: 30vw;
      }
    }
  }
}
