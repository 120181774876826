.about_container {
  width: 90vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 5vw;

  h2 {
    color: #f26739;
    font-size: 1.6vw;
    span {
      color: #f26739;
      font-size: 1vw;
      margin-right: 0.2vw;
    }
  }

  h3 {
    font-size: 2.5vw;
    margin: 2vw 0vw;
    text-align: center;
  }

  h4 {
    font-size: 2vw;
  }
  p,
  li {
    font-size: 1.8vw;
    padding-top: 1vw;
    color: #4d4d4d;
  }

  .mission,
  .vision,
  .team,
  .reasons {
    width: 100%;
    display: grid;
    margin-top: 4vw;
  }

  .mission {
    grid-template-columns: 60% auto;
    align-items: center;

    img {
      height: 20vw;
    }
  }
  .vision {
    grid-template-columns: 40% auto;
    align-items: center;

    img {
      height: 20vw;
    }
  }
  .team {
    grid-template-columns: 60% auto;
    align-items: center;

    img {
      height: 20vw;
    }
  }
}
