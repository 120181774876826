@use "../../config/colors";

.discover_tab {
  width: 35vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 4vw 0vw;
  margin-right: 4vw;
  margin-left: 2vw;

  &:hover {
    scale: 1.01;
  }

  .tab_content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 0.2vw solid colors.$primary;
    box-shadow: colors.$box_shadow;
    padding: 1.6vw 1vw;
    border-radius: 1vw;
    cursor: pointer;

    .float {
      min-width: 40%;
      width: fit-content;
      padding: 0.2vw 2vw;
      background-color: colors.$primary_wash;
      border: 0.2vw solid colors.$primary;
      box-shadow: colors.$box_shadow;
      border-radius: 5vw;
      align-self: flex-end;
      margin-top: -3.5vw;
      margin-bottom: 1vw;
      p {
        font-size: 4vw;
        color: colors.$primary;
      }
    }

    p {
      font-size: 4vw;
      text-align: center;
    }
    h3 {
      font-size: 5vw;
    }

    .status {
      width: fit-content;
      margin: 1vw 0vw;
      h2 {
        font-size: 5vw;
        color: colors.$white;
      }
    }

    .status_invalid {
      h2 {
        color: colors.$red;
      }
    }
    .status_valid {
      h2 {
        color: colors.$green;
      }
    }

    .notice {
      color: colors.$primary;
    }
    .notice_sel {
      color: colors.$white;
    }
  }
  .selected {
    background-color: colors.$primary;
  }
  .active {
    background-color: colors.$primary;
  }

  .circle_cnt {
    width: fit-content;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    rotate: -20deg;

    .circle_a,
    .circle_b,
    .circle_c {
      width: 2vw;
      height: 2vw;
      background-color: colors.$primary;
      margin: 0.1vw;
      border-radius: 2vw;
    }

    .circle_b {
      width: 1.4vw;
      height: 1.4vw;
    }
    .circle_c {
      width: 1vw;
      height: 1vw;
    }
  }
}
