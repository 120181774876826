@use "../config/colors";

.discover_container {
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 5vw;

  h2 {
    color: #f26739;
    font-size: 1.6vw;
    span {
      color: #f26739;
      font-size: 1vw;
      margin-right: 0.2vw;
    }
  }

  h3 {
    font-size: 2.5vw;
    margin: 2vw 0vw;
    text-align: center;
  }

  .discover {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;

    .tabs_cnt {
      width: 80%;
      height: fit-content;
      display: flex;
      flex-direction: row;

      .overlay_cnt {
        width: 85vw;
        height: 85vh;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-evenly;
        background-color: colors.$white;
        overflow-y: scroll;
        border-radius: 1vw;
      }

      .load_btn {
        width: fit-content;
        height: fit-content;
        padding: 1vw 1.5vw;
        align-self: center;
        border-radius: 1vw;
        box-shadow: colors.$box_shadow;
        background-color: colors.$primary_wash;
        cursor: pointer;

        &:hover {
          scale: 1.06;
        }

        p {
          font-size: 1.2vw;
          color: colors.$primary;
        }
      }
      .dud_load_btn {
        width: fit-content;
        height: fit-content;
        padding: 1vw 1.5vw;
        align-self: center;
        border-radius: 1vw;
        background-color: colors.$shade;
        opacity: 0.8;

        p {
          font-size: 1.2vw;
          color: colors.$grey;
        }
      }
    }

    .discover_content {
      width: 80vw;
      height: fit-content;
      display: grid;
      grid-template-columns: 70% auto;
      margin-bottom: 4vw;
      border-radius: 2vw;
      padding: 2vw;
      box-shadow: colors.$box_shadow;

      .image_cnt {
        width: 100%;
        height: 85vh;

        img {
          height: 100%;
        }
      }

      .redirect_btn_ctn {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;

        .redirect_btn {
          width: fit-content;
          height: fit-content;
          padding: 1vw 2vw;
          border-radius: 1vw;
          margin: 5vw 0vw;

          &:hover {
            scale: 1.02;
          }
          cursor: pointer;
          p {
            font-size: 1.4vw;
          }
        }

        h4 {
          font-size: 1.6vw;
          margin-bottom: 1vw;
        }
        p {
          font-size: 1.4vw;
        }
      }
    }
  }

  .footer {
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #5a270c;
    padding: 2vw 0vw;
    p {
      color: #fff;
      font-size: 1.6vw;
    }
  }
}
