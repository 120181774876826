.contact_container {
  width: 100vw;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 5vw;
  background-color: #fde1d9;
  padding: 5vw 0vw;

  .contact_content {
    width: 90vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    h2 {
      color: #f26739;
      font-size: 1.6vw;
      span {
        color: #f26739;
        font-size: 1vw;
      }
    }

    h3 {
      font-size: 2.5vw;
      margin: 2vw 0vw;
      text-align: center;
    }

    .address_cnt {
      width: 100%;

      li {
        font-size: 1.8vw;
        color: #4d4d4d;
        margin-bottom: 2vw;
      }

      .email_cnt {
        width: 100%;
        display: grid;
        grid-template-columns: 50% auto;
      }
    }

    .social_media {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-top: 8vw;

      .media_links {
        width: 100%;
        display: flex;
        flex-direction: row;
        margin-top: 5vw;
      }

      img {
        width: 30vw;
        height: fit-content;
      }

      a {
        text-decoration: none;
        margin: 0vw 2vw;
        &:hover {
          opacity: 0.8;
        }
      }
    }
  }
}
