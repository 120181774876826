@use "../../config/colors";

.services_container {
  width: 90vw;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 4vw;

  .service_ct {
    width: 80vw;
    background-color: colors.$white;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 2vw;
    margin: 2vw 0vw;
    padding: 3vw;
    cursor: pointer;
    position: relative;
    box-shadow: 0 1px 1px hsl(0deg 0% 0% / 0.075), 0 2px 2px #f2673917,
      0 4px 4px #f2673917, 0 8px 8px #f2673917, 0 16px 16px #f2673917;

    .icon_circle {
      width: 16vw;
      height: 16vw;
      margin-top: -1.8vw;
      background-color: #fde1d9;
      border: 0.1vw solid #f26739;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        height: 8vw;
      }
    }
    p {
      margin-top: 1vw;
      font-size: 6vw;
      text-align: center;
      margin-bottom: 0.5vw;
      color: colors.$primary;
    }
    h4,
    h5 {
      color: colors.$black;
      text-align: center;
      font-size: 5.4vw;
      font-weight: 400;
      margin: 1vw 2vw;
    }
    h5 {
      color: colors.$grey;
    }
  }
}
