$primary: #f26739;
$primary_wash: #fde1d9;
$secondary: #5a270c;
$white: #fff;
$black: #000;
$red: #f23939;
$green: #33cf40;
$grey: #868484;
$shade: #c0c0c0;
$box_shadow: 0 1px 1px hsl(0deg 0% 0% / 0.075), 0 2px 2px #f2673917,
  0 4px 4px #f2673917, 0 8px 8px #f2673917, 0 16px 16px #f2673917;
