/* BackgroundImage.css */
.background-container {
  width: 100vw;
  max-height: 110vw;
  height: 100vh;
  background-size: cover;
  background-position: center;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0); /* Optional overlay color */
  pointer-events: none; /* Allow clicks through the overlay */
}

.content {
  max-width: 1800px;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  position: relative; /* Ensure child components are positioned above the background */
  z-index: 1; /* Make sure child components are on top */
}

@media (max-width: 850px) {
  .background-container {
    max-height: 150vh;
    height: 110vh;
  }
}
@media (max-width: 768px) {
  .background-container {
    max-height: 100vh;
  }
}
