.highlight {
  width: 20vw;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;

  .icon {
    width: 6vw;
    height: 6vw;
    border-radius: 50%;
    background-color: #f267392a;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 2.8vw;
    }
  }
  p,
  h3 {
    margin-top: 1.2vw;
    text-align: center;
    font-size: 1.4vw;
  }
  p {
    color: #4d4d4d;
  }
}
