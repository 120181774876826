@use "../../config/colors";

.container {
  width: 90vw;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;

  .float_ct {
    width: 95%;
    min-height: 30vh;
    height: fit-content;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    padding: 5vw 2vw;
    border-radius: 2vw;
    margin-top: -3vw;
    z-index: 1003;
    background-color: #fff;
    box-shadow: colors.$box_shadow;
  }

  .tech_ct {
    width: 100%;
    margin-top: 8vw;
    display: flex;
    flex-direction: row;
    img {
      width: 50vw;
      height: fit-content;
    }

    .tech_cnt {
      width: 100%;
      margin-top: 50vw;
      margin-left: -20vw;
      h2 {
        color: #f26739;
        font-size: 6vw;
        span {
          color: #f26739;
          font-size: 3vw;
          margin-right: 0.2vw;
        }
      }

      h3 {
        font-size: 5vw;
        margin: 2vw 0vw;
      }
      p {
        width: 80%;
        padding-left: 5.5vw;
        font-size: 4.5vw;
        color: #4d4d4d;
      }
    }
  }

  .services_ct {
    width: 100vw;
    background-color: #fde1d9;
    margin-top: 10vw;
    padding: 6vw 0vw;
    display: flex;
    flex-direction: column;
    align-items: center;

    .se_cnt {
      width: 90vw;
      display: flex;
      flex-direction: column;
      align-items: center;
      h2 {
        color: #f26739;
        font-size: 6vw;
        span {
          color: #f26739;
          font-size: 3vw;
          margin-right: 0.2vw;
        }
      }

      h3 {
        font-size: 5vw;
        margin: 2vw 0vw;
        text-align: center;
      }
    }
  }
}
